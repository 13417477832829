
import { Box, Container, CssBaseline, FormControl, RadioGroup, FormControlLabel, Radio, TextField, Button, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, SwipeableDrawer, Modal, Backdrop, Fade } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CarDetails, CarImages, UserPage } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "../utils/AuthSession";
import BidConfirmation from "../components/BidConfirmation";
import axios from 'axios'
import BidStatus from "../components/BidStatus";
import CarBidHistory from "../components/CarBidHistory";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Message from "../components/Message";
import ImageCarousel from "../components/ImageCarousel";
import { thousandSeperator } from "../utils/Format";
import Loading from "../components/Loading";
import { CgSpinner } from "react-icons/cg";

export default function ViewCarPage({ socket }) {
    const { car_id } = useParams();
    const [wholecar, setWholecar] = useState(true)
    const [bidAmount, setBidAmount] = useState(0)
    const [submitted, setSubmitted] = useState(false)
    const [bidError, setBidError] = useState('')
    const [highestBids, setHighestBids] = useState([]);
    const [isTermsChecked, setIsTermsChecked] = useState(false)
    const navigate = useNavigate()
    const [carStatus, setCarStatus] = useState('');
    const [loadingCarStatus, setLoadingCarStatus] = useState(false);
    const [carDetails, setCarDetails] = useState([])
    const [loadingCarDetails, setLoadingCarDetails] = useState(false)
    const [checkingBidValue, setCheckingBidValue] = useState(false);
    const [confirmingBid, setConfirmingBid] = useState(false);
    const [showingConfirmationModal, setShowingConfirmationModal] = useState(false);
    // const [refreshStatus, setRefreshStatus] = useState(0)

    useEffect(() => {
        socket.on('Bid Made', data => {
            fetchStatus()
            console.log('Bid Made envent triggered 1...!');
        });

        return () => {
            setHighestBids([])
        }
    }, []);


    // carStatus
    // {
    //     1 : 'Car from expired session'
    //     2 : 'No Car Found',
    //     3 : 'Car found and is from active session',
    // }

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        checkIfCarIsFromActiveSession()
    }, [car_id])

    useEffect(() => {
        // console.log('fetching status for view car...');
        fetchStatus()
    }, [car_id])

    const fetchStatus = () => {
        const user = getUser()
        const user_id = user.id
        axios.post(`${process.env.REACT_APP_API_URL}/bids/highestBid`, { user_id, car_id })
            .then(res => {
                console.log('Updated Bid Status (ViewCarPage) - ', res.data);
                setHighestBids(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const checkBidValue = () => {
        setBidError('');
        setCheckingBidValue(true);

        const user = getUser();

        axios.post(`${process.env.REACT_APP_API_URL}/bids/checkBid`,
            {
                user_id: user.id,
                car_id: car_id,
                bid_amount: bidAmount,
                wholecar: wholecar,
            }
        )
            .then(res => {
                if (res.data) {
                    setState({ ...state, bottom: true })
                }
                setCheckingBidValue(false);
            })
            .catch(err => {
                setBidError(err.response.data);
                setCheckingBidValue(false);
            })
    }

    const submitBid = () => {
        setBidError('');
        setConfirmingBid(true);
        setShowingConfirmationModal(true);

        const user = getUser();
        axios.post(`${process.env.REACT_APP_API_URL}/bids/bidcar`,
            {
                user_id: user.id,
                car_id: car_id,
                car_reg: carDetails.car_reg,
                model: carDetails.model,
                bid_amount: bidAmount,
                wholecar: wholecar,
                highestBids,
                bidded_at: new Date()
            }
        )
            .then(res => {
                if (res.data.submitted) {
                    setSubmitted(true)
                } else {
                    setState({ ...state, bottom: false })
                    setBidError(res.data.bidError)
                }
                setConfirmingBid(false);
            })
            .catch(err => {
                setBidError(err.response.data);
                setConfirmingBid(false);
            })
    }

    const checkIfCarIsFromActiveSession = () => {
        setLoadingCarStatus(true);
        axios.post(`${process.env.REACT_APP_API_URL}/cars/isCarInActiveSession`,
            {
                carId: car_id
            }
        )
            .then(res => {
                if (res.data.session_status === 0) { //Car found but is from expired session
                    setCarStatus(1)
                }
                else if (res.data.session_status === undefined) { //Car not found
                    setCarStatus(2)
                }
                else if (res.data.session_status === 1) { //Car found and is from active session
                    setCarStatus(3)
                }
                setLoadingCarStatus(false);
            })
            .catch(err => {
                alert("Can't access page at the moment");
                setLoadingCarStatus(false);
            })
    }

    const seperatedValue = () => {
        if (bidAmount > 0) {
            return thousandSeperator(bidAmount)
        }
        else if (bidAmount === '') {
            return ''
        }
        else {
            return 0
        }
    }

    const handleClick = () => {
        const input = document.getElementById('thousand-seperated-bidAmount')
        input.focus()
    }
    const handleKeyDown = () => {
        const input = document.getElementById('bid-input')
        input.focus()
    }
    const handleKeyUp = () => {
        const input = document.getElementById('thousand-seperated-bidAmount')
        input.focus()
    }

    return (
        <UserPage>
            {/* <p style={{ padding: '2rem', fontWeight: 'bold', background: 'white', position: 'fixed', top: 0, left: 0, zIndex:100 }}>{JSON.stringify(refreshStatus)}</p> */}
            {loadingCarDetails
                ? <Loading top='50' />
                : <>
                    {!submitted
                        ?
                        <>
                            {car_id
                                &&
                                <>
                                    {loadingCarStatus
                                        &&
                                        <Loading top='50' />
                                    }
                                    {carStatus === 1
                                        &&
                                        <Message message='This session has expired' type='danger' />
                                    }
                                    {carStatus === 2
                                        &&
                                        <Message message='No Car Found' type='danger' />
                                    }
                                    {carStatus === 3
                                        &&
                                        <Container maxWidth="lg" sx={{ mt: 2 }}>
                                            <CssBaseline />
                                            <Box>
                                                <CarImages swipable={true} car_id={car_id} />
                                            </Box>
                                            <Box>
                                                <CarDetails backBtn={true} car_id={car_id} highestBids={highestBids} wholecar={wholecar} setCarDetails={details => setCarDetails(details)} />
                                            </Box>

                                            <CarBidHistory user_id={getUser().id} car_id={car_id} />

                                            <div className='error-msg'>{bidError}</div>

                                            {/* bid form */}
                                            <Box>
                                                <section className='bidding-form-container'>
                                                    <FormControl className="radio-container">
                                                        <RadioGroup
                                                            className="radio-grp"
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={() => { setWholecar(!wholecar) }}
                                                            defaultValue={true}
                                                        >
                                                            <FormControlLabel value={true} control={<Radio className="radio1"
                                                            // onClick={() => { setWholecar(true); console.log(wholecar); }}
                                                            />} label="Whole Car" />

                                                            <FormControlLabel value={false} control={<Radio className="radio2"
                                                            // onClick={() => { setWholecar(false); console.log(wholecar);}}
                                                            />} label="Body Only" />

                                                        </RadioGroup>
                                                    </FormControl>
                                                    <>
                                                        {wholecar
                                                            ?
                                                            <>
                                                                {
                                                                    highestBids.length > 0
                                                                        ?
                                                                        <>
                                                                            {
                                                                                highestBids.length === 1 && highestBids[0].car_or_body === 'Whole Car'
                                                                                    ?
                                                                                    <>
                                                                                        <BidStatus bid_amount={highestBids[0].bid_amount} status={highestBids[0].bid_status} car_or_body='Whole Car' />
                                                                                    </>

                                                                                    : ''
                                                                            }
                                                                            {
                                                                                highestBids.length === 2
                                                                                    ?
                                                                                    <>
                                                                                        <BidStatus bid_amount={highestBids[0]?.bid_amount} status={highestBids[0].bid_status} car_or_body='Whole Car' />
                                                                                    </>

                                                                                    : ''
                                                                            }
                                                                        </>

                                                                        : ''
                                                                }
                                                            </>

                                                            :
                                                            <>
                                                                {
                                                                    highestBids.length > 0
                                                                        ?
                                                                        <>
                                                                            {
                                                                                highestBids.length === 1 && highestBids[0].car_or_body === 'Body Only'

                                                                                    ?
                                                                                    <>
                                                                                        <BidStatus bid_amount={highestBids[0].bid_amount} status={highestBids[0].bid_status} car_or_body='Body Only' />
                                                                                    </>

                                                                                    : ''
                                                                            }
                                                                            {
                                                                                highestBids.length === 2
                                                                                    ?
                                                                                    <>
                                                                                        <BidStatus bid_amount={highestBids[1]?.bid_amount} status={highestBids[1].bid_status} car_or_body='Body Only' />
                                                                                    </>

                                                                                    : ''
                                                                            }
                                                                        </>

                                                                        : ''
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    <Grid item xs={12} className='bid-form'>
                                                        <div className='bid-input-container'>
                                                            <p className="dollor-icon">$</p>
                                                            <TextField
                                                                required
                                                                id="bid-input"
                                                                value={bidAmount}
                                                                type="number"
                                                                onFocus={() => { if (bidAmount === 0) setBidAmount('') }}
                                                                onBlur={() => { if (bidAmount === '') setBidAmount(0) }}
                                                                onChange={e => { setBidAmount(e.target.value) }}
                                                                // onClick={() => handleClick()}
                                                                // onKeyUp={() => handleKeyUp()}
                                                                min='0'
                                                                className='bid-input'
                                                            />
                                                            {/* <input
                                                                aria-disabled="true"
                                                                type='text'
                                                                value={seperatedValue()}
                                                                onFocus={() => { if (bidAmount === 0) setBidAmount('') }}
                                                                onBlur={() => { if (bidAmount === '') setBidAmount(0) }}
                                                                className='thousand-seperated-bidAmount'
                                                                id='thousand-seperated-bidAmount'
                                                                onKeyDown={() => handleKeyDown()}
                                                            /> */}
                                                            <p className="currency">SGD</p>
                                                        </div>
                                                        <Button onClick={() => checkBidValue()} disabled={bidAmount <= 0 || checkingBidValue} sx={{ gap: "5px" }}>
                                                            Bid Now
                                                            {checkingBidValue
                                                                &&
                                                                <CgSpinner className='spinner-icon' />
                                                            }
                                                        </Button>
                                                    </Grid>


                                                </section>

                                            </Box>

                                            <SwipeableDrawer
                                                anchor={'bottom'}
                                                open={state['bottom']}
                                                onClose={toggleDrawer('bottom', false)}
                                                onOpen={toggleDrawer('bottom', true)}
                                                className='confirmation-drawer'
                                            >
                                                <span className="notch"></span>
                                                <h3>{carDetails.model}</h3>
                                                <h4>Regn No: {carDetails.car_reg}</h4>
                                                <p>Your Bid &nbsp;-&nbsp; ${thousandSeperator(bidAmount)} {`(${wholecar ? 'Whole Car' : 'Body Only'})`}</p>
                                                <div className="terms-list">
                                                    1. Prospective buyers have the option to view the vehicle(s) that are up for bid, verify its mileage and test drive the vehicle(s) prior to bidding.
                                                    <br />
                                                    <br />

                                                    2. All test drives are subject to space allowance within the designated compound at 20km/h. No test drives are permitted outside the premises. Prospective buyers are liable to fully indemnify Wearnes Automotive Pte Ltd (“Wearnes”) for any damage sustained to the vehicle during such test drives.
                                                    <br />
                                                    <br />

                                                    3. All vehicles that are up for bid are awarded based on an “as is
                                                    condition”
                                                    <br />
                                                    <br />

                                                    4. All information relating to any vehicle that is up for bid is based on Wearnes’ best knowledge only. Wearnes shall not be liable for any loss or damage to any prospective buyer arising out of any inaccuracy or omission of information.
                                                    <br />
                                                    <br />

                                                    5. Wearnes reserves the right to make the final decision to withhold or award any vehicle that is up for bid. Wearnes shall not be liable for any loss or damage to a prospective buyer that may arise out of or in relation to Wearnes’ decision to withhold or award any vehicle.
                                                    <br />
                                                    <br />

                                                    6. ANY CANCELLATION AFTER THE BID IS AWARDED WILL
                                                    RESULT IN EITHER A 10% PENALTY OF THE SUCCESSFUL
                                                    BID PRICE OR A MINIMUM CANCELLATION FEE OF $2,000,
                                                    WHICHEVER IS HIGHER.
                                                    <br />
                                                    <br />

                                                    7. Payment by the successful bidder is to be made by FAST or PAYNOW.
                                                    <br />
                                                    <br />

                                                    8. Collection of all vehicles by a successful bidder are to be done only after payment is received by Wearnes. Location will be at 45 Leng Kee Singapore 159103 on the closest Thursday and Friday (between 10am to 5pm) from the date of award of the vehicle(s), or on any other date to be otherwise indicated by Wearnes.
                                                    <br />
                                                    <br />

                                                    9. In the interim period between the collection of the vehicle by the successful bidder and the official transfer of ownership of the vehicle to the successful bidder by the Land Transport Authority, the successful bidder shall be fully responsible for and shall fully indemnify Wearnes for any loss or damage arising out of or in relation to any traffic accidents, fines, summonses, or breach of any statutory or regulatory requirements

                                                </div>
                                                <div className="terms">
                                                    <input id='terms-checkbox' type='checkbox' checked={isTermsChecked} onChange={() => setIsTermsChecked(!isTermsChecked)} />
                                                    <p style={{ cursor: 'pointer' }} onClick={() => setIsTermsChecked(!isTermsChecked)}>I agree to the Terms & Conditions</p>
                                                </div>

                                                <div className="btn-container">
                                                    <button className="btn danger-btn" onClick={toggleDrawer('bottom', false)}>Back</button>
                                                    <button className="btn primary-btn" style={{ gap: "5px" }} disabled={!isTermsChecked || confirmingBid} onClick={() => submitBid()}>
                                                        Confirm
                                                        {confirmingBid
                                                            &&
                                                            <CgSpinner className='spinner-icon' />
                                                        }
                                                    </button>
                                                </div>
                                            </SwipeableDrawer>

                                            <Modal
                                                aria-labelledby="transition-modal-title"
                                                aria-describedby="transition-modal-description"
                                                open={showingConfirmationModal}
                                                onClose={() => setShowingConfirmationModal(false)}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                    timeout: 500,
                                                }}
                                                className='confirmation-modal'
                                            >
                                                <Fade in={showingConfirmationModal}>
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            width: 400,
                                                            boxShadow: 24,
                                                            p: 4,
                                                        }}
                                                    >
                                                        <h3>Confirming Your Bid</h3>
                                                        <hr className='hr'></hr>
                                                        <p>Please wait while your bid is being processed</p>
                                                    </Box>
                                                </Fade>
                                            </Modal>
                                        </Container>
                                    }
                                </>
                            }
                        </>

                        :
                        <Container maxWidth="sm" sx={{ mt: 2 }}>
                            <BidConfirmation car_id={car_id} bid_amount={bidAmount} />
                        </Container>
                    }
                </>
            }
        </UserPage >
    );
}


